﻿.events-article {
  .events-title {
    text-align: center;
    margin-top: 2rem;
  }

  .events-date {
    @include font-size(48,56);
    text-align: center;
    padding: 2rem;
    margin: 2rem 0;

    @media(min-width: $min-tablet) {
      @include font-size(64,72);
    }
  }
}
