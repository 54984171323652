footer {
  background: $footer;
  color: $footer-text;


  .footer-links {
    display: flex;
    padding: 24px 0;

    a {
      color: white;
      margin-right: 36px;
      transition: $duration $easing;

      &:hover {
        color: #37BEB0;
      }
    }
  }

  .footer-content {
    @include font-size(14);
    padding: $footer-spacing-mob;
    display: flex;
    justify-content: space-between;

    @media (min-width: $min-desktop) {
      padding: $footer-spacing;
    }
  }


  .copyright {
    background: $disclaimer;
    color: $disclaimer-text;

    .copyright-content {
      @include font-size(14);
      padding: $disclaimer-spacing;
      color: $disclaimer-text;

      @media (min-width: $min-desktop) {
        padding: $disclaimer-spacing;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .social {

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0;
      padding: 0;

      li {
        list-style: none;
        padding: 8px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        a {
          transition: $duration $easing;
          color: $accent;

          svg {
            width: 20px;
            height: 20px;
          }

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}
