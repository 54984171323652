.faqs {



  .accordion {   
    background: white; 
    cursor: pointer;
    padding: 15px 30px;
    width: 100%;
    transition: $duration $easing; 
    display: flex;
    justify-content: space-between;  
    align-items: center;
    border: 1px solid $border;
    border-radius: 30px;
    margin-top: 24px;


    .plus-icon {  
      width: 20px;
      height: 20px;
      position: relative;

      &:before,
      &:after {
        content: "";
        position: absolute;
        background-color: $accent;
        transition: transform $duration $easing;
      }

      &:before {
        top: 0;
        left: 50%;
        width: 4px;
        height: 100%;
        margin-left: -2px;
      }

      &:after {
        top: 50%;
        left: 0;
        width: 100%;
        height: 4px;
        margin-top: -2px;
      }
    } 

    .arrow-icon {
      height: 20px;
      width: 35px;
      display: block;
      position: relative;
      cursor: pointer;


      .left-bar,
      .right-bar {
        position: absolute;
        background-color: transparent;
        top: 50%;
        width: 20px;
        height: 5px;
        display: block;
        border-radius: 2px;

        &:after {
          content: "";
          background-color: $accent;
          width: 20px;
          height: 5px;
          display: block;  
          transition: all $duration $easing;
          z-index: -1;
        }
      }

      .left-bar { 
        left: 0;    
        transform: translateY(-50%) rotate(35deg);    

        &:after {         
          border-radius: 6px 10px 10px 6px;      
        }
      }

      .right-bar {   
        left: 15px;    
        transform: translateY(-50%) rotate(-35deg);   

        &:after {     
          border-radius: 10px 6px 6px 10px;      
        }
      }
    }



      
    &.active, &:hover {
      background-color: $accent;
      color: $accent-text;

      .plus-icon {
        &:before,
        &:after {
          background-color: $accent-text;
        }
      }

      .arrow-icon { 
        .left-bar,
        .right-bar {

          &:after {
            background-color: $accent-text;
          }
        }
      }
    }    

    &.active {

      .plus-icon {  
        &:before { transform: rotate(90deg); }
        &:after { transform: rotate(180deg); }
      }

      .arrow-icon {

        .left-bar:after {
          transform-origin: center center;
          transform: rotate(-70deg);
        }

        .right-bar:after {
          transform-origin: center center;
          transform: rotate(70deg);
        }

      }
          
    }

  }

  .panel {
    max-height: 0;   
    overflow: hidden;
    transition: $duration $easing;

    &.active {
      max-height: 600px;
    }

    .wrapper {
      padding: $block-padding;
    }

    p:last-child {
      margin: 0;
    }
  }



 

 
}