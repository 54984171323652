﻿.news-article {
  margin-top: $vertical-space-mob;

  @media(min-width: $min-tablet) {
    margin-top: $vertical-space;
  }
}

.news-article-image {
  @include font-size(14);
  margin-bottom: $spacing-small;
}

.news-info-container {

  @media(min-width: $min-tablet) {
    display: flex;

    > div {
      flex: 1;
    }
  }

  .news-article-author {
    @include font-size(14);
    margin-bottom: $spacing-small;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: $min-tablet) {
      flex-direction: row;
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    .auhor-details {
      margin-top: 16px;
      text-align: center;

      @media(min-width: $min-tablet) {
        margin-left: 16px;
        text-align: left;
        margin-top: 0;
      }

      span {
        display: block;
      }

      p {
        margin-bottom: 0.5rem;
      }

      .author-name {
        @include font-size(18);
        font-weight: 600;
      }
    }
  }

  .news-tags-cat {
    text-align: center;

    @media(min-width: $min-tablet) {
      text-align: right;
    }

    span {
      @include font-size(14);
    }

    .news-article-categories {
      margin-bottom: $spacing-small;
    }

    .news-article-tags {
      margin-bottom: $spacing-small;
    }
  }
}

.news-article-content {
  margin-bottom: $spacing-small;
}

.article-date {
  @include font-size(14);
  margin: 0.5rem 0;
}

.news-article-featured {
  margin: $vertical-space-mob auto;
  @media(min-width: $min-tablet) {
    margin: $vertical-space auto;
  }
}