.map-text {
  position: relative;
  display: flex;
  flex-direction: column;

  @media(min-width: $min-tablet) {
    flex-direction: row;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    order: 2;
    max-width: 450px;
    align-items: flex-start;
    padding: $vertical-space-mob $horizontal-space-mob;
  }

  .lazyGoogleMap {
    width: 100%;
    height: 70vh;

    @media(min-width: $min-tablet) {
      width: 45%;
      height: 50vh;
    }
  }

  @media(min-width: $min-tablet) {
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  }
}
