.listing {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;

  @media (min-width: $min-tablet ) {
    margin: 0;
  }

  .listing-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    
    .img {
      width: 100%;
      height: 220px;
      overflow: hidden;

      .img-holder {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        transition: $duration $easing;
      }

      &:hover {

        .img-holder {
          transform: scale(1.1);
        }
      }
    }

    .content {
      padding: $block-padding;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .date {
        @include font-size(12);
        text-transform: uppercase;
        color: $text-light;
        margin-bottom: 10px;
      }

      .title {
        @include font-size(24);
        margin-bottom: $spacing-small;

        a {
          text-decoration: none;
          color: inherit;
          transition: $duration $easing;

          &:hover {
            color: $accent;
          }
        }

        @media (min-width: $min-tablet ) {
          @include font-size(32);
        }
      }

      .author {
        @include font-size(14);
      }

      .button {
        margin-top: $spacing;
      }
    }
  }



  &.grid {


    @media (min-width: $min-tablet ) {
      .listing-item {
        max-width: 50%;
        padding-right: $grid-spacing;
        margin-bottom: $spacing;

        &:nth-child(even) {
          padding: 0 0 0 $grid-spacing;
        }

        .content {
          padding: $block-padding 0;

          .button {
            align-self: start;
          }
        }
      }

      &.three {
        .listing-item {
          max-width: 33.333333%;
          padding: 0 $grid-spacing;


          &:nth-child(3n) {
            padding: 0 0 0 $grid-spacing;
          }

          &:nth-child(3n+1) {
            padding: 0 $grid-spacing 0 0;
          }
        }
      }
    }

    @media (min-width: $min-desktop ) {

      &.four {
        .listing-item {
          max-width: 25%;
          padding: 0 $grid-spacing;

          &:nth-child(4n+1) {
            padding: 0 $grid-spacing 0 0;
          }

          &:nth-child(4n) {
            padding-right: 0;
          }
        }
      }
    }
  }


  &:not(.grid) {

    .listing-item {
      @media (min-width: $min-tablet ) {
        margin: $spacing 0;

        .img {
          width: 50%;
          order: 2;
          height: 100%;
        }

        .content {
          order: 1;
          width: 50%;

          .button {
            align-self: start;
          }
        }
      }
    }

    &.alternate {

      .listing-item {

        @media (min-width: $min-tablet ) {

          .img {
            width: 48%;
          }


          .content {
            padding: $block-padding 60px $block-padding $block-padding;
            width: 50%;
            margin-right: 2%;
          }

          &:nth-child(even) {

            .img {
              order: 1;
            }

            .content {
              margin: 0 0 0 2%;
              order: 2;
              padding: $block-padding $block-padding $block-padding 60px;
            }
          }
        }
      }
    }
  }
}
