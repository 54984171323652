.pagination {
  margin: $spacing 0;
  display: flex;
  justify-content: center;

  .pag-button {
    background: $accent;
    color: $accent-text;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $accent;
    transition: $duration $easing;

    &:hover {
      background: $accent-text;
      color: $accent;
      text-decoration: none;
    }
  }

  .pag-numbers {
    display: flex;
    align-items: center;

    span {
      margin: 0 5px;
      font-weight: 700;
    }
   

    .pag-number {
      border-radius: 50%;
      border: 1px solid $border;
      width: 55px;
      height: 55px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text;
      transition: $duration $easing;


      &:hover,
      &.current {
        border: 1px solid $accent;
        text-decoration: none;
      }

      &.current {
        color: $accent;
      }
    }
    
  }
}