
.fade-enter-active,.fade-leave-active {
  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out
}

.fade-enter,.fade-leave-to {
  opacity: 0
}

.ss-main,input {
  color: #666
}

.ss-main {
  position: relative;
  display: inline-block;
  user-select: none;
  width: 100%
}

.ss-main .ss-single-selected {
  display: flex;
  cursor: pointer;
  width: 100%;  
  padding: 16px 24px;
  border: 1px solid $border-color;
  border-radius: 48px;
  background-color: #FFF;
  outline: 0;
  box-sizing: border-box;
  transition: background-color $duration;
  min-height: 55px;
  margin-bottom: 0;
}

.ss-main .ss-single-selected.ss-disabled {
  background-color: $border-color;
  cursor: not-allowed;
}



.ss-main .ss-single-selected .placeholder {
  flex: 1 1 100%;
  text-align: left;
  width: calc(100% - 30px);
  color: $text-light;
  user-select: none;
}

.ss-main .ss-single-selected .placeholder,.ss-main .ss-single-selected .placeholder * {
  
  
  display: flex;
  
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.ss-main .ss-single-selected .placeholder * {
  width: auto
}

.ss-main .ss-single-selected .placeholder .ss-disabled {
  color: $text-light;
}

.ss-main .ss-single-selected .ss-deselect {
  
  
  display: flex;
  
  
  align-items: center;

  justify-content: flex-end;

  flex: 0 1 auto;
  margin: 0 6px;
  font-weight: 700;
}

.ss-main .ss-single-selected .ss-deselect.ss-hide {
  display: none
}

.ss-main .ss-single-selected .ss-arrow {
  
  
  display: flex;
  
  
  align-items: center;

  justify-content: flex-end;

  flex: 0 1 auto;
  margin: 0 6px
}

.ss-main .ss-single-selected .ss-arrow span {
  border: solid #666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;

  transition: transform $duration,margin $duration,-webkit-transform $duration
}

.ss-main .ss-single-selected .ss-arrow span.arrow-up {

  transform: rotate(-135deg);
  margin: 3px 0 0
}

.ss-main .ss-single-selected .ss-arrow span.arrow-down {

  transform: rotate(45deg);
  margin: -3px 0 0
}

.ss-main .ss-multi-selected {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  padding: 16px 24px;
  border: 1px solid $border-color;
  border-radius: 48px;
  background-color: #FFF;
  outline: 0;
  box-sizing: border-box;
  transition: background-color $duration
}

.ss-main .ss-multi-selected.ss-disabled {
  background-color: #DCDEE2;
  cursor: not-allowed
}

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-disabled {
  color: #666
}

.ss-main .ss-multi-selected.ss-disabled .ss-values .ss-value .ss-value-delete {
  cursor: not-allowed
}

.ss-main .ss-multi-selected.ss-open-above {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}


.ss-main .ss-multi-selected .ss-values {
    
  display: flex;
  margin-bottom: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1 1 100%;
  width: calc(100% - 30px)
}

.ss-main .ss-multi-selected .ss-values .ss-disabled {
    
  display: flex;
  align-items: center;
  width: 100%;
  color: $text-light;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

@-webkit-keyframes scaleIn {

  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }


}

@keyframes scaleIn {

  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }


}

@-webkit-keyframes scaleOut {

  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  to {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
  }


}

@keyframes scaleOut {

  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  to {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
  }


}

.ss-main .ss-multi-selected .ss-values .ss-value {
  @include font-size(14,20);
  font-family: $bold;
  display: flex;
  user-select: none;  
  align-items: center; 
  padding: 4px 8px;
  margin: 0 4px 0 0;
  color: #FFF;
  background-color: $accent;
  border-radius: 26px;
  animation-name: scaleIn;
  animation-duration: $duration;
  animation-timing-function: ease-out;
  animation-fill-mode: both
}

.ss-main .ss-multi-selected .ss-values .ss-value.ss-out {

  animation-name: scaleOut;
  animation-duration: $duration;
  animation-timing-function: ease-out
}

.ss-main .ss-multi-selected .ss-values .ss-value .ss-value-delete {
  margin: 0 0 0 5px;
  cursor: pointer
}

.ss-main .ss-multi-selected .ss-add {
  
  
  display: flex;

  flex: 0 1 3px;
  margin: 9px 12px 0 5px
}

.ss-main .ss-multi-selected .ss-add .ss-plus {
   
  display: flex;  
  justify-content: center; 
  align-items: center;
  background: #666;
  position: relative;
  height: 10px;
  width: 2px;
  transition: transform $duration,-webkit-transform $duration
}

.ss-main .ss-multi-selected .ss-add .ss-plus:after {
  background: #666;
  content: "";
  position: absolute;
  height: 2px;
  width: 10px;
  left: -4px;
  top: 4px;
}

.ss-main .ss-multi-selected .ss-add .ss-plus.ss-cross {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ss-content {
  position: absolute;
  width: 100%;
  margin: -1px 0 0 ;
  box-sizing: border-box;
  border-radius: 16px;
  z-index: 1010;
  background-color: $dropdown-bg;
  transform-origin: center top;
  transition: transform $duration,opacity $duration,-webkit-transform $duration;
  opacity: 0;
  transform: scaleY(0);
}

.ss-content.ss-open {
  display: block;
  opacity: 1;
  transform: scaleY(1);
}

.ss-content .ss-search {
   
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.ss-content .ss-search.ss-hide,.ss-content .ss-search.ss-hide input {
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}

.ss-content .ss-search input {  
  
  display: inline-flex;
  font-size: inherit;
  line-height: inherit;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
  height: 30px;
  padding: 8px;
  margin: 0;
  border: 1px solid $border-color;
  border-radius: 48px;
  background-color: #FFF;
  outline: 0;
  text-align: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: textfield
}

.ss-content .ss-search input::-webkit-input-placeholder {
  color: $text-light;
  vertical-align: middle
}

.ss-content .ss-search input::-moz-placeholder {
  color: $text-light;
  vertical-align: middle
}

.ss-content .ss-search input:-ms-input-placeholder {
  color: $text-light;
  vertical-align: middle
}

.ss-content .ss-search input::-ms-input-placeholder {
  color: $text-light;
  vertical-align: middle
}

.ss-content .ss-search input::placeholder {
  color: $text-light;
  vertical-align: middle
}

.ss-content .ss-search input:focus {
  border-color: $text;
}

.ss-content .ss-search .ss-addable {
  
  
  display: inline-flex;
  
  
  justify-content: center;
  
  
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  height: 30px;
  margin: 0 0 0 8px;
  border: 1px solid $border-color;
  border-radius: 48px;
  box-sizing: border-box;
}

.ss-content .ss-addable {
  padding-top: 0;
}

.ss-content .ss-list {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}

.ss-list::-webkit-scrollbar {
  width: 5px;
}

.ss-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $dropdown-bg; 
  border-radius: 10px;
}


.ss-list::-webkit-scrollbar-thumb {

  border-radius: 48px;
  -webkit-box-shadow: inset 0 0 30px $accent; 

}

​


.ss-content .ss-list .ss-optgroup .ss-optgroup-label {
  padding: 8px 12px;
  font-weight: 700;
}

.ss-content .ss-list .ss-optgroup .ss-option {
  padding: 6px 6px 6px 25px;
}

.ss-content .ss-list .ss-optgroup-label-selectable {
  cursor: pointer;
}

.ss-content .ss-list .ss-optgroup-label-selectable:hover {
  color: #FFF;
  background-color: #5897FB
}

.ss-content .ss-list .ss-option {
  padding: 8px 24px;
  cursor: pointer;
  user-select: none;
  transition: $duration $easing;
  color: $text;
}

.ss-content .ss-list .ss-option * {
  display: inline-block
}

.ss-content .ss-list .ss-option.ss-highlighted,.ss-content .ss-list .ss-option:hover {
  color: $accent-text;
  background-color: $accent
}

.ss-content .ss-list .ss-option.ss-disabled {
  cursor: not-allowed;
  color: $text-light;
}

.ss-content .ss-list .ss-option:not(.ss-disabled).ss-option-selected {
  color: $text;
  background-color: rgba(88,151,251,.1)
}

.ss-content .ss-list .ss-option.ss-hide {
  display: none
}

.ss-content .ss-list .ss-option .ss-search-highlight {
  background-color: $accent;
}

