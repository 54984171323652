.tabs {
  width: 100%;

}

[role="tablist"] {
  margin: 0 0 -0.1em;
  overflow: visible;
}

[role="tab"] {
  position: relative;
  margin: 0;
  padding: 15px $block-padding ;
  border: 1px solid $border; 
  overflow: visible;

 }



[role="tab"][aria-selected="true"] {
  border-radius: 0;
  background: $accent;
  color: $accent-text;
  outline: 0;
}

[role="tab"][aria-selected="true"]:not(:focus):not(:hover)::before {
  border-top: 5px solid $accent;
}

[role="tab"][aria-selected="true"]::after {
  position: absolute;
  z-index: 3;
  bottom: -1px;
  right: 0;
  left: 0;
  height: 0.3em;
  background: $accent;
  box-shadow: none;
  content: '';
}

[role="tab"]:hover,
[role="tab"]:focus,
[role="tab"]:active {
  outline: 0;
  border-radius: 0;

}

[role="tab"]:hover::before,
[role="tab"]:focus::before {
  border-color: $accent;
}

[role="tabpanel"] {
  position: relative;
  z-index: 2;
  padding: $block-padding;
  border: 1px solid $border;
  
}

[role="tabpanel"]:focus {
 
  outline: 0;
}

[role="tabpanel"]:focus::after {
  position: absolute;
  bottom: 0;
  right: -1px;
  left: -1px; 
  content: '';
}

[role="tabpanel"] p {
  margin: 0;
}

[role="tabpanel"] * + p {
  margin-top: 1em;
}