
.image-with-caption {
  width: 100%;
  position: relative;
  margin: 0 auto;
 

  .img-caption-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 200px;

    @media (min-width: $min-tablet) {
      height: 268px;
    }

    @media (min-width: $min-desktop) {
      height: 440px;
    }
  } 

  .img-caption-img,  
  .video-inner {
    margin-bottom: 15px;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .video-inner {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  }

  p.caption { 
    width: 100%;
    font-style: italic;
    margin-bottom: 0;
  }
}

