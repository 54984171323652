.results {

  .result {
    padding: $spacing 0;

    .title {
      @include font-size(24);
      font-weight: 700;
      margin-bottom: $spacing-small;
    } 

  }

}
