.image-text {
   
  position: relative;
  display: flex;
  flex-direction: column;

  @media(min-width: $min-tablet) {
    flex-direction: row;
  }

  .content {
    position: relative; 
    display: flex;
    flex-direction: column;     
    order: 2;
    max-width: 450px;
    align-items: flex-start; 
    padding: $vertical-space-mob $horizontal-space-mob;

  }

  .img {    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    order: 1;

    img {
      width: 100%;
    }
  }

  @media(min-width: $min-tablet) {

    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .img {        
      align-self: flex-start;
      width: 45%;
      
    }

    &.img-right {

      .content {
        order: 1;
      }

      .img {
        order: 2;
      }
    }

  }


  
}