.filter-form {
  margin-bottom: $vertical-space-mob;

  @media(min-width: $min-tablet) {
    margin-bottom: $vertical-space;
  }
}

.filters {
  display: flex;
  flex-flow: wrap;

  .filter {
    flex: 0 0 100%;
    margin-bottom: $grid-spacing;

    @media(min-width: $small) {
      flex: 0 0 50%;
      padding-right: $grid-spacing;
    }

    @media(min-width: $min-tablet) {
      flex: 1 0 auto;
    }

    &:last-child {
      @media(min-width: $min-tablet) {
        padding-right: 0;
      }
    }
  }
}